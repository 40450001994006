<template>
  <div>

    <div class="grid grid-cols-2 mb-4">
      <div class="col-span-1">
        <h2 class="text-2xl font-bold">All Transfers</h2>
      </div>
      <div class="col-span-1">
        <div class="relative">
          <input
            type="search"
            name="search"
            class="border border-solid border-blue-200 rounded placeholder-blue-200 outline-none pl-10 pr-2 py-2 text-xs w-full"
            placeholder="Search for employee’s name, status, etc."
            v-model="transfers.query">
          <ion-icon name="search-outline" class="text-blue-200 absolute z-10 text-lg ml-3 left-0" style="top: 50%; transform: translateY(-50%);"></ion-icon>
        </div>
      </div>
    </div>

    <div class="border border-solid border-blue-200 overflow-hidden">
      <datatable
        :data="transfers.data"
        :columns="transfers.columns"
        :loading="transfers.loading"
        :query="transfers.query"
        :onClick="click"
        ref="table"
      />
    </div>

    <modal className="w-full md:w-3/5 xl:w-5/10 pt-10" ref="viewModal">
      
      <h4 class="text-xl font-bold mb-6">Transfer Request</h4>

      <template v-if="transfers.selected">
        <div class="grid grid-cols-1 md:grid-cols-2 border-b border-t border-blue-200">
          <div class="col-span-1 p-4">
            <div class="opacity-75 text-sm font-medium mb-1">Requested By</div>
            <div class="text-gray-500 text-sm font-light flex flex-row items-center">
              <ion-icon name="person-outline" class="text-lg mr-2" style="width: 17px; flex: 0 0 17px;"></ion-icon>
              {{ transfers.selected.requester.name }}
            </div>
          </div>
          <div class="col-span-1 p-4 border-t md:border-t-0 md:border-l border-blue-200">
            <div class="opacity-75 text-sm font-medium mb-1">Category</div>
            <div class="text-gray-500 text-sm font-light flex flex-row items-center">
              <ion-icon name="albums-outline" class="text-lg mr-2" style="width: 17px; flex: 0 0 17px;"></ion-icon>
              {{ transfers.selected.expense_category_id.label }}
            </div>
          </div>
        </div>

        <div class="grid grid-cols-1 md:grid-cols-2 border-b border-blue-200" style="margin-top: -1px">
          <div class="col-span-1 p-4">
            <div class="opacity-75 text-sm font-medium mb-1">Transfer Label</div>
            <div class="text-gray-500 text-sm font-light flex flex-row items-center">
              <ion-icon name="pricetag-outline" class="text-lg mr-2" style="width: 17px; flex: 0 0 17px;"></ion-icon>
              {{ transfers.selected.label }}
            </div>
          </div>
          <div class="col-span-1 p-4 border-t md:border-t-0 md:border-l border-blue-200">
            <div class="opacity-75 text-sm font-medium mb-1">Transfer Description</div>
            <div class="text-gray-500 text-sm font-light flex flex-row items-center">
              <ion-icon name="reader-outline" class="text-lg mr-2" style="width: 17px; flex: 0 0 17px;"></ion-icon>
              {{ transfers.selected.description }}
            </div>
          </div>
        </div>


        <div class="grid grid-cols-1 md:grid-cols-2 border-b border-blue-200" style="margin-top: -1px">
          <div class="col-span-1 p-4">
            <div class="opacity-75 text-sm font-medium mb-1">Transfer Amount</div>
            <div class="text-gray-500 text-sm font-light flex flex-row items-center">
              <ion-icon name="cash-outline" class="text-lg mr-2" style="width: 17px; flex: 0 0 17px;"></ion-icon>
              {{ transfers.selected.amount | currency }}
            </div>
          </div>
          <div class="col-span-1 p-4 border-t md:border-t-0 md:border-l border-blue-200">
            <div class="opacity-75 text-sm font-medium mb-1">Transfer Status</div>
            <div class="text-gray-500 text-sm font-light flex flex-row items-center">
              <ion-icon name="square-outline" class="text-lg mr-2" style="width: 17px; flex: 0 0 17px;"></ion-icon>
              {{ transfers.selected.status }}
            </div>
          </div>
        </div>

        <div class="grid grid-cols-1 md:grid-cols-2 border-b border-blue-200" style="margin-top: -1px">
          <div class="col-span-1 p-4">
            <div class="opacity-75 text-sm font-medium mb-1">Beneficiary</div>
            <div class="text-gray-500 text-sm font-light flex flex-row items-center">
              <ion-icon name="happy-outline" class="text-lg mr-2" style="width: 17px; flex: 0 0 17px;"></ion-icon>
              {{ transfers.selected.beneficiary.name }}
            </div>
          </div>
          <div class="col-span-1 p-4 border-t md:border-t-0 md:border-l border-blue-200">
            <div class="opacity-75 text-sm font-medium mb-1">Request Date</div>
            <div class="text-gray-500 text-sm font-light flex flex-row items-center">
              <ion-icon name="calendar-outline" class="text-lg mr-2" style="width: 17px; flex: 0 0 17px;"></ion-icon>
              {{ transfers.selected.created_at | dateFormat('dd M, Y - h:i:sa') }}
            </div>
          </div>
        </div>

        <div class="grid grid-cols-1 md:grid-cols-2 border-b border-blue-200 mb-10" style="margin-top: -1px">
          <div class="col-span-1 p-4">
            <div class="opacity-75 text-sm font-medium mb-1">Beneficiary Account Number</div>
            <div class="text-gray-500 text-sm font-light flex flex-row items-center">
              <ion-icon name="file-tray-full-outline" class="text-lg mr-2" style="width: 17px; flex: 0 0 17px;"></ion-icon>
              {{ transfers.selected.beneficiary.account_number }}
            </div>
          </div>
          <div class="col-span-1 p-4 border-t md:border-t-0 md:border-l border-blue-200">
            <div class="opacity-75 text-sm font-medium mb-1">Beneficiary Bank</div>
            <div class="text-gray-500 text-sm font-light flex flex-row items-center">
              <ion-icon name="business-outline" class="text-lg mr-2" style="width: 17px; flex: 0 0 17px;"></ion-icon>
              {{ transfers.selected.beneficiary.bank_id | bankName($store.state.banks) }}
            </div>
          </div>
        </div>

        <template v-if="getFormError(form)">
          <div class="alert alert-red-soft mb-16">
            <div class="alert-icon">!</div>
            <span>{{ getFormError(form) }}</span>
          </div>
        </template>

        <div class="text-center" v-if="transfers.selected.status === 'pending'">
          <div class="mb-7">
            <div class="text-sm text-gray-700 mb-2">Select the wallet to bill from.</div>
            <custom-select
              :options="walletOptions"
              v-model="form.data.wallet.value"
              class="pl-5 pr-8 py-2 border border-blue-200 text-gray-700 rounded text-left inline-block text-sm custom-select"
            />
          </div>
          <div>
            <button type="button" class="btn btn-blue mr-3" @click.prevent="approve" :disabled="form.loading">
              <span v-if="form.loading">Approving</span>
              <span v-else>Approve</span>
            </button>
            <button type="button" class="btn btn-red" :disabled="form.loading">
              Decline
            </button>
          </div>
        </div>

      </template>
      <template v-else>
        <div class="text-sm text-gray-700 mb-10">Please select a transfer to view.</div>
      </template>

    </modal>

    <modal className="w-full md:w-2/5 xl:w-3/10 text-center pt-24" ref="approvalSuccessModal">
      <img :src="assets.icons.lg.checkmark" class="mx-auto mb-8" height="90" alt="Check Mark">

      <div class="text-lg font-bold mb-4">
        Success!
      </div>
      <div class="text-sm mb-10">
        Transfer Request Approved.
      </div>

      <button type="button" class="btn btn-blue" @click.prevent="$refs.approvalSuccessModal.close">
        Okay
      </button>
    </modal>
    
    <modal className="w-full md:w-2/5 xl:w-3/10 text-center pt-24" ref="declinationSuccessModal">
      <img :src="assets.icons.lg.checkmark" class="mx-auto mb-8" height="90" alt="Check Mark">

      <div class="text-lg font-bold mb-4">
        Success!
      </div>
      <div class="text-sm mb-10">
        Transfer Request Declined.
      </div>

      <button type="button" class="btn btn-blue" @click.prevent="$refs.declinationSuccessModal.close">
        Okay
      </button>
    </modal>
    
  </div>
</template>
<script>
  export default {
    data() {
      return {
        walletOptions: [
          { title: 'Credit Wallet', value: 'credit' },
          { title: 'Debit Wallet', value: 'debit' },
        ],
        transfers: this.$options.resource([], {
          columns: [
            {
              name: 'label',
              th: 'Transfer Label',
            },
            {
              name: 'expense_category_id',
              th: 'Category',
              render: (t, cat) => cat.label
            },
            {
              name: 'requester',
              th: 'Requested By',
              render: (t, requester) => requester.name
            },
            {
              name: 'beneficiary',
              th: 'Department',
              render: (t, beneficiary) => beneficiary.name
            },
            {
              name: 'amount',
              th: 'Amount',
              render: (t, amount) => `₦ ${this.$options.filters.currency(amount)}`
            },
            {
              name: 'status',
              th: 'Status',
              render: (company, status) => {
                switch (status) {
                  case 'approved':
                    return `<div class="badge badge-green-soft-outline">
                      Confirmed
                    </div>`;
                  case 'successful':
                    return `<div class="badge badge-green-soft-outline">
                      Successful
                    </div>`;
                  case 'declined':
                    return `<div class="badge badge-red-soft-outline">
                      Declined
                    </div>`;
                  case 'pending':
                  default:
                    return `<div class="badge badge-orange-soft-outline">
                      Pending
                    </div>`;
                }
              }
            }
          ],
          query: '',
          selected: null
        }),
        form: this.$options.basicForm([{ name: 'wallet', value: 'credit' }]),
      }
    },
    beforeMount() {
      this.getTransfers();
    },
    methods: {
      click(transfer) {
        this.transfers.selected = transfer;
        this.$refs.viewModal.open();
      },
      async getTransfers() {
        this.transfers.loading = true;

        await this.sendRequest('corporate.transfers.all', this.user.company_id, {
          success: response => {
            this.transfers.setValue(response.data.transfers);
            this.transfers.loaded = true;
          },
          error: error => {
            this.transfers.error = error;
          }
        });

        this.transfers.loading = false;
      },
      async approve() {
        this.form.loading = true;

        await this.sendRequest('corporate.transfers.approve', {
          data: {
            transfer_req_id: this.transfers.selected?.id,
            wallet: this.form.data.wallet.value 
          },
          success: () => {
            this.$refs.viewModal.close();
            this.$refs.approvalSuccessModal.open();
            this.getTransfers();
          },
          error: error => {
            this.form.error = error;
          }
        });

        this.form.loading = false;
      },
      async decline() {
        this.form.loading = true;

        await this.sendRequest('corporate.transfers.decline', {
          success: () => {
            this.getTransfers();
          },
          error: error => {
            this.form.error = error;
          }
        });

        this.form.loading = false;
      },
    }
  }
</script>